import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hook";

const MentorPro = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const { isAuth, user } = useAppSelector((state) => state.auth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);
  if (!isAuth || !user?.allAccess)
    return (
      <div>
        <h1>MentorPro</h1>
        <p>Vous devez être abonné pour accéder à cette page</p>
      </div>
    );
  return (
    <div
      style={{
        position: "relative",
        height: height * 0.8,
        width: width * 0.9,
      }}
    >
      <iframe
        allowFullScreen={true}
        id="mentorProIframe"
        title="MentorPro"
        src={
          "https://mentor.mesvaccins.net/iframe?travel=true&pro=true&lang=fr"
        }
        style={{
          border: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  );
};

export default MentorPro;
